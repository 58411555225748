import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Image } from 'react-native';
import Logo from './components/Logo';
import Cnt from './assets/cnt.PNG';

export default function App() {
  return (
    <View style={styles.mainContainer}>
      <View style={styles.container}>
        <Logo />
        <Text style={styles.subText} >Haptic, audio and visual R&D</Text>
      </View>
      <View style={styles.footer}>
        <Image style={styles.footerImage} source={Cnt} />
      </View>
      
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subText: {
    fontSize: 22,
  },
  footer: {
    alignSelf: 'flex-end',
    paddingRight: 15,
    paddingBottom: 15
  },
  footerImage: {
    minWidth: 148,
    minHeight: 31,
  }
});
